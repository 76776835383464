/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CateringInvoicePayment {
  ach = "ach",
  card = "card",
}

export enum CateringInvoiceStatus {
  awaiting_payment = "awaiting_payment",
  paid = "paid",
  pending = "pending",
  refunded = "refunded",
  voided = "voided",
}

export enum CateringOverageRequestStatus {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
}

export enum CateringPreferredContactMethod {
  contact_by_email = "contact_by_email",
  contact_by_phone = "contact_by_phone",
  contact_by_phone_or_email = "contact_by_phone_or_email",
  contact_by_text = "contact_by_text",
}

export enum CateringProposalStatus {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
}

export enum CateringRequestStatus {
  cancelled = "cancelled",
  confirmed = "confirmed",
  finished = "finished",
  organizer_assigned = "organizer_assigned",
  organizer_confirmed = "organizer_confirmed",
  pending = "pending",
  truck_selection = "truck_selection",
  trucks_notified = "trucks_notified",
}

export enum CateringRequestStepStatus {
  completed = "completed",
  failed = "failed",
  in_progress = "in_progress",
  pending = "pending",
}

export enum ItemQuestionType {
  checkbox = "checkbox",
  dropdown = "dropdown",
}

export enum LocationItemStatus {
  active = "active",
  disabled = "disabled",
}

export enum LocationWorkStatus {
  pre_order = "pre_order",
  work_ended = "work_ended",
  work_not_started = "work_not_started",
  work_paused = "work_paused",
  work_started = "work_started",
}

export enum OrderStatus {
  abandoned = "abandoned",
  cancelled = "cancelled",
  completed = "completed",
  in_progress = "in_progress",
  pending = "pending",
  ready = "ready",
  refused = "refused",
}

export enum PriceLineTag {
  coupon = "coupon",
  total = "total",
}

export enum Status {
  cancelled = "cancelled",
  finished = "finished",
  orders_created = "orders_created",
  requires_information = "requires_information",
  truck_assigned = "truck_assigned",
  truck_search = "truck_search",
}

export enum TruckScheduleEntryType {
  ExternalEvent = "ExternalEvent",
  Location = "Location",
}

export interface CartItemInput {
  locationItemId: number;
  quantity: number;
  questions: CartItemQuestionInput[];
  source?: string | null;
  specialInstructions: string;
  upsell?: boolean | null;
}

export interface CartItemQuestionInput {
  answerIds: number[];
  id: number;
}

export interface CateringProposalSelection {
  proposalVariantIds: number[];
  truckGroupId: number;
}

export interface MealPartsInput {
  count: number;
  id: number;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
