import { ApolloClient, gql } from '@apollo/client'

import { cartRemoveItem, cartRemoveItemVariables } from './types/cartRemoveItem'

const MUTATION = gql`
  mutation cartRemoveItem($cartUuid: String!, $itemIndex: Int!) {
    cartRemoveItem(cartUuid: $cartUuid, itemIndex: $itemIndex) {
      cart {
        id
        uuid
      }
      errors {
        fullMessage
        key
        message
      }
    }
  }
`

type Args = {
  client: ApolloClient<object>
  cartUuid: string
  itemIndex: number
}

export default function removeItem({ client, cartUuid, itemIndex }: Args) {
  return client.mutate<cartRemoveItem, cartRemoveItemVariables>({
    mutation: MUTATION,
    variables: { cartUuid: cartUuid!, itemIndex },
    refetchQueries: ['checkoutCartQuery']
  })
}
