import React from 'react'
import format from 'date-fns/format'
import NoticeBlock, { NoticeVariant } from 'components/NoticeBlock'
import { LocationWorkStatus } from 'types/graphql-global-types.d'
import * as Types from 'global-graphql-types'

type LocationNoticeProp = {
  id: number
  startTime: string
  allowOrders: boolean
  workStatus: LocationWorkStatus | Types.LocationWorkStatus
  workStatusHuman: string
}

type Props = {
  location: LocationNoticeProp
}

function noticeText(location: LocationNoticeProp) {
  const startTime = format(new Date(location.startTime), 'E L/d, h:mm aaa')
  if (location.workStatus === LocationWorkStatus.work_ended) {
    return `The truck has ended this shift.`
  }
  if (!location.allowOrders) {
    return 'This truck does not allow online orders'
  }

  if (location.workStatus === LocationWorkStatus.work_not_started) {
    return `The truck is not accepting orders yet. The shift is scheduled to start on ${startTime}.`
  }

  if (location.workStatus === LocationWorkStatus.pre_order) {
    return `Shift doesn't start until ${startTime}. Your order should be ready at your chosen time. We'll text you when your order is confirmed and when your food is ready.`
  }

  if (location.workStatus === LocationWorkStatus.work_paused) {
    return `Uh oh, the truck is backed up. Check back soon, but please don't approach the truck until your confirmed order is called.`
  }
  return null
}

export function hasNotice(location: LocationNoticeProp) {
  return !!noticeText(location)
}

export default function LocationNotice({ location }: Props) {
  const notice = noticeText(location)
  if (!notice) return null

  let variant: NoticeVariant = 'info-secondary'
  if (location.workStatus === 'work_ended') variant = 'danger'
  return (
    <NoticeBlock
      message={notice}
      title={
        location.allowOrders ? location.workStatusHuman : 'Order ahead disabled'
      }
      variant={variant}
    />
  )
}
