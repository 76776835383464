/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import TextField from 'components/ui/TextField'

type TextFieldProps = {
  label?: string
  description?: string
  placeholder?: string
  autoComplete?: string
  type?: string
}

export type Props = TextFieldProps & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerFormTextField({
  input,
  label,
  description,
  autoComplete,
  placeholder,
  meta: { touched, error: syncError, submitError, submitting }
}: FieldRenderProps<string, HTMLInputElement> & TextFieldProps) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error
  return (
    <TextField
      autoComplete={autoComplete}
      description={description}
      disabled={submitting}
      error={showError ? error : null}
      label={label}
      placeholder={placeholder}
      {...input}
    />
  )
}

export default function FormTextField({
  name,
  type,
  fieldProps = {},
  ...innerProps
}: Props) {
  return (
    <Field<string, FieldRenderProps<string, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => (
        <InnerFormTextField
          {...props}
          {...innerProps}
          name={name}
          type={type}
        />
      )}
      type={type}
      {...fieldProps}
    />
  )
}
