import { ApolloClient, gql } from '@apollo/client'
import { CartItemInput } from 'types/graphql-global-types'

import { cartUpdateItem, cartUpdateItemVariables } from './types/cartUpdateItem'

const MUTATION = gql`
  mutation cartUpdateItem(
    $cartUuid: String!
    $item: CartItemInput!
    $itemIndex: Int!
  ) {
    cartUpdateItem(cartUuid: $cartUuid, item: $item, itemIndex: $itemIndex) {
      cart {
        id
        uuid
      }
      errors {
        fullMessage
        key
        message
      }
    }
  }
`

type Args = {
  cartUuid: string
  item: CartItemInput
  itemIndex: number
  client: ApolloClient<object>
}

export default function updateItem({
  cartUuid,
  item,
  itemIndex,
  client
}: Args) {
  return client.mutate<cartUpdateItem, cartUpdateItemVariables>({
    mutation: MUTATION,
    variables: { cartUuid: cartUuid!, item, itemIndex },
    refetchQueries: ['checkoutCartQuery']
  })
}
