import React, { useMemo } from 'react'
import { useQuery, gql, useApolloClient } from '@apollo/client'
import PageError from 'components/pages/PageError'
import BaseItemModal from 'components/ItemModal/BaseItemModal'
import useConfirm from 'hooks/useConfirm'
import updateItem from 'components/ItemModal/EditItemModal/updateItem'
import removeItem from 'components/ItemModal/EditItemModal/removeItem'
import { ItemQuestionType } from 'types/graphql-global-types.d'
import NullsToUndefined from 'utils/NullsToUndefined'
import getMutationErrors from 'utils/getMutationErrors'

import {
  locationItemQuery,
  locationItemQueryVariables
} from '../types/locationItemQuery'
import ITEM_QUERY from '../itemQuery'
import { SubmitValues } from '../itemModalTypes'
import getCartItemInput from '../getCartItemInput'

import { editItemModalCartItemFragment as editItemModalCartItemFragmentType } from './types/editItemModalCartItemFragment'

export const editItemModalCartItemFragment = gql`
  fragment editItemModalCartItemFragment on CartItem {
    id
    quantity
    specialInstructions
    locationItem {
      id
      item {
        id
        image {
          thumb
        }
      }
    }
    questions {
      locationItemQuestion {
        id
        text
      }
      answers {
        id
        text
      }
    }
  }
`

type Props = {
  id: number
  cartUuid: string
  visible: boolean
  cartItem: NullsToUndefined<editItemModalCartItemFragmentType>
  cartItemIndex: number
  onUpdate?: () => void
  closeModal: () => void
}

const EditItemModal = ({
  visible,
  id,
  closeModal,
  cartUuid,
  cartItem,
  cartItemIndex,
  onUpdate
}: Props) => {
  const confirmation = useConfirm()
  const client = useApolloClient()

  const { error, data } = useQuery<
    locationItemQuery,
    locationItemQueryVariables
  >(ITEM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
    skip: !visible
  })

  const initialValues = useMemo(() => {
    const seed: SubmitValues = {
      quantity: cartItem.quantity,
      specialInstructions: cartItem.specialInstructions
    }

    if (!data) return seed

    return cartItem.questions.reduce((prev, selectedQuestion) => {
      const key = `question-${selectedQuestion.locationItemQuestion.id}`
      const itemQuestion = data.locationItem.locationItemQuestions.find(
        (liq) => liq.id === selectedQuestion.locationItemQuestion.id
      )?.itemQuestion

      if (!itemQuestion) return prev

      switch (itemQuestion.questionType) {
        case ItemQuestionType.checkbox: {
          // eslint-disable-next-line no-param-reassign
          prev[key] = selectedQuestion.answers.map((a) => a.id)
          break
        }
        case ItemQuestionType.dropdown: {
          // eslint-disable-next-line no-param-reassign,prefer-destructuring
          prev[key] = selectedQuestion.answers.map((a) => a.id)[0]
          break
        }
        default: {
          throw new Error(`Unknown question type: ${itemQuestion.questionType}`)
        }
      }
      return prev
    }, seed)
  }, [cartItem, data])

  if (error) {
    return <PageError>{error.message}</PageError>
  }
  if (!data) return null

  const locationItem = data.locationItem!

  const onSubmit = async (values: SubmitValues) => {
    const item = getCartItemInput({
      questions: locationItem.locationItemQuestions,
      values,
      id
    })
    if (values.quantity === 0) {
      const proceed = await confirmation({
        title: 'Confirm',
        message: `Remove ${locationItem.item.name}?`,
        okButton: 'Yes, remove it',
        cancelButton: 'Go Back'
      })
      if (!proceed) return null

      const response = await removeItem({
        client,
        cartUuid: cartUuid!,
        itemIndex: cartItemIndex
      })
      const errors = getMutationErrors(response)

      if (errors) return errors
    } else {
      const response = await updateItem({
        client,
        cartUuid: cartUuid!,
        item,
        itemIndex: cartItemIndex
      })
      const errors = getMutationErrors(response)
      if (errors) return errors
    }
    if (onUpdate) {
      onUpdate()
    }
    closeModal()
    return {}
  }

  return (
    <BaseItemModal
      closeModal={closeModal}
      initialValues={initialValues}
      locationItem={locationItem}
      modalIsOpen={visible}
      onSubmit={onSubmit}
      forUpdate
    />
  )
}
export default EditItemModal
