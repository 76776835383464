import PageLayout from 'components/pages/PageLayout'
import Loading from 'components/Loading'
import React from 'react'
import PageMeta from 'components/PageMeta'
import { useRouter } from 'next/router'

type Props = Omit<Parameters<typeof PageLayout>[0], 'children'> & {
  meta?: React.ReactNode
}

const LoadingPage = ({ staticPage = true, meta, ...props }: Props) => {
  const router = useRouter()
  const pathWithoutQuery = router.asPath.split('?')[0]
  // eslint-disable-next-line no-param-reassign
  meta = meta ?? <PageMeta canonicalPath={pathWithoutQuery} />
  return (
    <>
      {meta}
      <PageLayout staticPage={staticPage} {...props}>
        <Loading className="w-screen h-screen" />
      </PageLayout>
    </>
  )
}

export default LoadingPage
