import React from 'react'
import { gql } from '@apollo/client'
import cn from 'classnames'
import { PriceLineTag } from 'types/graphql-global-types.d'
import { CartPage } from 'components/pages/CartPage/types/query'

import { priceLinesFragment_priceLinesCustomer as priceLinesFragmentPriceLinesCustomer } from './types/priceLinesFragment'
import { cartPricesFragment_lines as cartPricesFragmentLines } from './types/cartPricesFragment'

type Props = {
  lines:
    | (cartPricesFragmentLines | priceLinesFragmentPriceLinesCustomer)[]
    | NonNullable<CartPage['currentCart']>['price']['lines']
}

export const priceLinesFragment = gql`
  fragment priceLinesFragment on Order {
    priceLinesCustomer {
      amount {
        cents
        formatted
      }
      name
      alwaysVisible
      tags
    }
  }
`

export const cartPricesFragment = gql`
  fragment cartPricesFragment on CartPrice {
    lines {
      amount {
        cents
        formatted
      }
      name
      alwaysVisible
      tags
    }
  }
`

export default function CartPrices({ lines }: Props) {
  return (
    <div>
      {lines.map((line, index) => {
        if (line.amount?.cents === 0 && !line.alwaysVisible) return null

        // @ts-ignore
        const isTotalLine = line.tags.includes(PriceLineTag.total)
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${line.name}-${index}`}
            className={cn('flex flex-row justify-between pt-2', {
              'border-solid border-gray-300': isTotalLine,
              'border-t mt-2 pt-4': isTotalLine && lines.length > 1
            })}
          >
            <p
              className={cn({
                'text-base': isTotalLine,
                'text-sm text-gray-700': !isTotalLine
              })}
            >
              {line.name}
            </p>
            {line.amount ? (
              <p
                className={cn({
                  'text-base': isTotalLine,
                  'text-sm text-gray-700': !isTotalLine
                })}
              >
                {line.amount.formatted}
              </p>
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </div>
  )
}
