import { gql } from '@apollo/client'
import { itemTagFragment } from 'components/Tag/'

import { itemQuestionsFragment } from './ItemModalQuestions'

export default gql`
  ${itemQuestionsFragment}
  ${itemTagFragment}
  query locationItemQuery($id: Int!) {
    locationItem(id: $id) {
      item {
        id
        name
        tags {
          ...itemTagFragment
        }
      }
      ...itemQuestionsFragment
    }
  }
`
