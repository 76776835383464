import { ApolloClient, gql } from '@apollo/client'
import { CartItemInput } from 'types/graphql-global-types'

import { cartAddItem, cartAddItemVariables } from './types/cartAddItem'

const MUTATION = gql`
  mutation cartAddItem($cartUuid: String!, $item: CartItemInput!) {
    cartAddItem(cartUuid: $cartUuid, item: $item) {
      cart {
        id
      }
      errors {
        fullMessage
        key
        message
      }
    }
  }
`

type Args = {
  client: ApolloClient<object>
  cartUuid: string
  item: CartItemInput
}

export default function addItem({ client, cartUuid, item }: Args) {
  return client.mutate<cartAddItem, cartAddItemVariables>({
    mutation: MUTATION,
    variables: { cartUuid: cartUuid!, item },
    refetchQueries: ['checkoutCartQuery']
  })
}
