import React, { useContext, useEffect, useMemo } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import {
  ItemQuestionType,
  LocationWorkStatus
} from 'types/graphql-global-types.d'
import useConfirm from 'hooks/useConfirm'
import PageError from 'components/pages/PageError'
import useCart from 'queries/useCart'
import { CartItemInput } from 'types/graphql-global-types'
import { useBlockUi } from 'components/BlockUi'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import getMutationErrors from 'utils/getMutationErrors'

import {
  locationItemQuery,
  locationItemQueryVariables
} from '../types/locationItemQuery'
import { SubmitValues } from '../itemModalTypes'
import getCartItemInput from '../getCartItemInput'
import BaseItemModal from '../BaseItemModal'
import ITEM_QUERY from '../itemQuery'
import { logEvent, setAdsUserData } from '../../../utils/analytics'
import useUser from '../../../queries/useUser'

import addItem from './addItem'

type Props = {
  id: number
  modalIsOpen: boolean
  upsell: boolean
  source: string
  locationId: number
  onAdd?: () => void
  closeModal: () => void
  notice?: React.ReactElement
  canOrder: boolean
}

const AddItemModal = ({
  modalIsOpen,
  canOrder,
  id,
  closeModal,
  locationId,
  upsell,
  source,
  notice,
  onAdd
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)
  const { user } = useUser({ skip: typeof window === 'undefined' })
  const blockUi = useBlockUi()
  const { cart, refetch } = useCart({ skip: !modalIsOpen })

  const confirmation = useConfirm()
  const client = useApolloClient()

  useEffect(() => {
    const initialOverflow = document.body.style.overflow
    return () => {
      document.body.style.overflow = initialOverflow
    }
  }, [])

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  }, [modalIsOpen])

  const { error, data } = useQuery<
    locationItemQuery,
    locationItemQueryVariables
  >(ITEM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
    skip: !modalIsOpen
  })

  useEffect(() => {
    if (!data?.locationItem && modalIsOpen) {
      blockUi.startBlockUi()
    }
    if (data?.locationItem) {
      blockUi.stopBlockUi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, modalIsOpen])

  const locationItem = data?.locationItem

  const initialValues: SubmitValues = useMemo(() => {
    const values: SubmitValues = { quantity: 1, specialInstructions: '' }

    if (!locationItem) return values

    locationItem.locationItemQuestions.forEach((q) => {
      const key = `question-${q.id}`
      switch (q.itemQuestion.questionType) {
        case ItemQuestionType.checkbox: {
          values[key] = []
          q.locationItemAnswers?.forEach((a) => {
            if (a.itemAnswer.default && a.id) {
              ;(values[key] as number[]).push(a.id)
            }
          })
          break
        }
        case ItemQuestionType.dropdown: {
          values[key] =
            q.locationItemAnswers?.find((a) => a.itemAnswer.default && a.id)
              ?.id || null
          break
        }
        default: {
          throw new Error(
            `Unknown question type: ${q.itemQuestion.questionType}`
          )
        }
      }
    })
    return values
  }, [locationItem])

  if (!modalIsOpen) return null
  if (error) {
    return <PageError>{error.message}</PageError>
  }
  if (!data) {
    return null
  }

  if (!locationItem) {
    throw new Error('location item is not present')
  }

  const onSubmit = async (values: SubmitValues) => {
    if (
      cart?.location?.id &&
      cart.location.id !== locationId &&
      cart.items.length > 0 &&
      cart.location.workStatus !== LocationWorkStatus.work_ended
    ) {
      const proceed = await confirmation({
        title: 'Confirm',
        message: `You have ${cart.items.length} ${
          cart.items.length === 1 ? 'item' : 'items'
        } from ${
          cart.location.truck.name
        } in your cart. They are going to be cleared to make space in the cart`,
        okButton: 'Yes, clear items',
        cancelButton: 'Go Back',
        albertsons
      })
      if (!proceed) return null
    }

    if (cart?.uuid) {
      const item: CartItemInput = getCartItemInput({
        questions: locationItem.locationItemQuestions,
        values,
        id
      })
      item.upsell = upsell
      item.source = source

      const response = await addItem({ client, cartUuid: cart!.uuid, item })
      const errors = getMutationErrors(response)

      if (errors) return errors

      refetch()
      closeModal()

      if (user) {
        await setAdsUserData(user)
      }
      logEvent('add_to_cart', {
        value: (locationItem.item.price.cents * item.quantity) / 100,
        currency: 'USD',
        items: [
          {
            item_id: locationItem.item.id,
            item_name: locationItem.item.name
          }
        ]
      })

      // add_to_cart_ads
      logEvent('conversion', {
        send_to: 'AW-768772864/jrYwCIiJn4kZEICWyu4C',
        value: (locationItem.item.price.cents * item.quantity) / 100,
        currency: 'USD',
        items: [
          {
            item_id: locationItem.item.id,
            item_name: locationItem.item.name
          }
        ]
      })

      if (onAdd) onAdd()
    }
    return {}
  }

  return (
    <BaseItemModal
      canOrder={canOrder}
      closeModal={closeModal}
      initialValues={initialValues}
      locationItem={locationItem}
      modalIsOpen={modalIsOpen}
      notice={notice}
      onSubmit={onSubmit}
    />
  )
}
export default AddItemModal
